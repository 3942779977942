import { takeLatest, select, call, put, all } from "redux-saga/effects";
import {
  success as noticeSuccess,
  error,
} from "react-notification-system-redux";
import store from "store";

import Api from "services/api/ApiService";
import ROUTES from "config/routes";
import logger from "services/logger";
import history from "services/history";
import authActions from "./actions";

function handleErrors(res) {
  if (res && res.errors) {
    throw new Error(res);
  }
  if (res && res.error) {
    throw new Error(res.error.message);
  }
}

function* initUserWatcher() {
  yield takeLatest("AUTH/INIT_USER", initUserEffect);
}
function* initUserEffect(action) {
  yield call(Api.setAuthToken, action.payload.token);
}

function* signInWatcher() {
  yield takeLatest("AUTH/SIGN_IN", signInEffect);
}
function* signInEffect(action) {
  try {
    yield put(authActions.signInRequest());
    const response = yield call(() => {
      return Api.signIn(action.payload).then((res) => {
        handleErrors(res);
        return res;
      });
    });
    if (response.message) {
      yield call(() => {
        store.dispatch(
          noticeSuccess({
            message: response.message,
          })
        );
      });
    }
    yield put(authActions.signInSuccess(response));
    yield put({ type: "PROFILE/GET_INFO" });
    yield call(() => Api.setAuthToken(response.token));
    yield call(() => history.push(ROUTES.home));
  } catch (error) {
    logger(error);
    yield put(authActions.signInFailure());
  }
}

function* signUpWatcher() {
  yield takeLatest("AUTH/SIGN_UP", signUpEffect);
}
function* signUpEffect(action) {
  try {
    yield put(authActions.signUpRequest());
    const response = yield call(() => {
      return Api.signUp(action.payload).then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield put(authActions.signUpSuccess(response));
    yield call(() =>
      history.push({
        pathname: ROUTES.signUpConfirmed,
      })
    );
    yield call(() => {
      store.dispatch(
        noticeSuccess({
          message: response.message,
        })
      );
    });
  } catch (error) {
    logger(error);
    yield put(authActions.signUpFailure());
  }
}

function* signUpPagesWatcher() {
  yield takeLatest("AUTH/SIGN_UP_PAGES", signUpPagesEffect);
}
function* signUpPagesEffect(action) {
  try {
    yield put(authActions.signUpPagesRequest());
    const response = yield call(() => {
      return Api.signUpPages().then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield put(authActions.signUpPagesSuccess(response.data));
  } catch (error) {
    logger(error);
    yield put(authActions.signUpFailure());
  }
}

function* signUpPagesSlugWatcher() {
  yield takeLatest("AUTH/SIGN_UP_PAGES_SLUG", signUpPagesSlugEffect);
}
function* signUpPagesSlugEffect(action) {
  try {
    yield put(authActions.signUpPagesSlugRequest());
    const response = yield call(() => {
      return Api.signUpPagesSlug(action.params).then((res) => {
        handleErrors(res);
        return res;
      });
    });

    yield put(authActions.signUpPagesSlugSuccess(response.data));
  } catch (error) {
    logger(error);
    yield put(authActions.signUpPagesSlugFailure());
  }
}

function* forgotPasswordWatcher() {
  yield takeLatest("AUTH/FORGOT_PASSWORD", forgotPasswordEffect);
}
function* forgotPasswordEffect(action) {
  try {
    const response = yield call(() => {
      return Api.forgotPassword(action.payload).then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield call(() => history.push(ROUTES.home));
    yield call(() => {
      store.dispatch(
        noticeSuccess({
          message: response.message,
        })
      );
    });
  } catch (error) {
    logger(error);
  }
}

function* restorePasswordWatcher() {
  yield takeLatest("AUTH/RESTORE_PASSWORD", restorePasswordEffect);
}
function* restorePasswordEffect(action) {
  try {
    const response = yield call(() => {
      return Api.restorePassword(action.payload).then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield call(() => history.push(ROUTES.signIn));
    yield call(() => {
      store.dispatch(
        noticeSuccess({
          message: response.message,
        })
      );
    });
  } catch (error) {
    logger(error);
  }
}

function* resetWatcher() {
  yield takeLatest("AUTH/RESET", resetEffect);
}
function* resetEffect(action) {
  yield call(() => {
    Api.setAuthToken("");
  });
  yield put({ type: "PROFILE/RESET" });
  // yield call(history.push, ROUTES.home);
}

function* logoutWatcher() {
  yield takeLatest("AUTH/LOGOUT", logoutEffect);
}
function* logoutEffect(action) {
  try {
    yield put(authActions.logoutRequest());
    const response = yield call(() => {
      return Api.logOut().then((res) => {
        handleErrors(res);
        return res;
      });
    });
    yield put(authActions.logoutSuccess());
    yield put({ type: "PROFILE/RESET" });
    yield call(() => {
      Api.setAuthToken("");
    });
    yield call(history.push, ROUTES.home);
  } catch (error) {
    logger(error);
    yield put(authActions.logoutFailure());
  }
}

function* confirmResendWatcher() {
  yield takeLatest("AUTH/CONFIRM_RESEND", confirmResendEffect);
}
function* confirmResendEffect(action) {
  try {
    const { email } = action.payload;
    const response = yield call(() => {
      return Api.confirmResend(email).then((res) => {
        handleErrors(res);
        return res;
      });
    });

    yield call(() => {
      store.dispatch(
        noticeSuccess({
          message: response.message,
        })
      );
    });
  } catch (error) {
    logger(error);
  }
}

export default function* authWatchers() {
  yield all([
    initUserWatcher(),
    resetWatcher(),
    logoutWatcher(),
    signInWatcher(),
    signUpWatcher(),
    signUpPagesWatcher(),
    forgotPasswordWatcher(),
    restorePasswordWatcher(),
    confirmResendWatcher(),
    signUpPagesSlugWatcher(),
  ]);
}
